import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../style/navbar.css'; // Ensure this file contains basic styles for the Navbar
import logo from '../components/logo.png';

const Navbar = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate

  // Navigate to login page on logout
  const onLogout = () => {
    // Optional: Clear authentication status from localStorage if needed
    localStorage.removeItem('isAuthenticated'); 
    navigate('/login'); // Redirect to login page when logout is clicked
  };

  return (
    <motion.div
      className="navbar"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
    >
      <div className="left-section">
        {/* Logo Link */}
       
          <img src={logo} alt="Company Logo" className="navbar-logo" />
        
      </div>

      <h1 className="navbar-title">Welcome to Machaxi</h1>

      {/* Logout button always visible */}
      <motion.button
        className="logout-button"
        onClick={onLogout}  // Trigger navigation to login
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        Logout
      </motion.button>
    </motion.div>
  );
};

export default Navbar;
