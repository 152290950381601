import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import '../style/login.css';
import { motion } from "framer-motion";

const LoginPage = ({ handleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleLoginClick = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    if (!username || !password) {
      setError("All fields are required");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post("https://admin.machaxi.com/summer-api/login2", { 
        username, 
        password 
      });

      handleLogin();
      navigate("/quotation");

    } catch (error) {
      if (error.response) {
        setError(error.response.data.error || "Login failed");
      } else {
        // console.error("Login error:", error);
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      className="login-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.2 }}
    >
      <motion.div
        className="login-form"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          Welcome
        </motion.h2>

        <form onSubmit={handleLoginClick}>
          <motion.input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            whileFocus={{ scale: 1.05, borderColor: "#00bfff" }}
            transition={{ duration: 0.3 }}
            className="input"
            required
          />

          <div className="password-input-container">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input password-input"
              required
            />
            <button
              type="button"
              className="toggle-password-visibility"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="20" 
                height="20" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                {passwordVisible ? (
                  <>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12s2.5-4.5 9-4.5 9 4.5 9 4.5-2.5 4.5-9 4.5S3 12 3 12z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </>
                ) : (
                  <>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.803 17.803a10.96 10.96 0 01-1.586.848m-2.217-.963A10.967 10.967 0 0112 18.92c-5.09 0-9.672-3.596-10.499-4.348a1.009 1.009 0 010-1.224C2.328 10.675 6.91 7.08 12 7.08c.541 0 1.07.042 1.578.123m-1.678 9.438A10.967 10.967 0 0112 18.92c5.09 0 9.672-3.596 10.499-4.348a1.009 1.009 0 000-1.224C21.672 10.675 17.09 7.08 12 7.08c-.541 0-1.07.042-1.578.123" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3l18 18" />
                  </>
                )}
              </svg>
            </button>
          </div>

          <button
            className="button"
            type="submit"
            disabled={loading}
          >
            {loading ? "Logging in..." : "Log In"}
          </button>
        </form>

        {error && (
          <p className="error-message">
            {error}
          </p>
        )}
      </motion.div>
    </motion.div>
  );
};

export default LoginPage;
