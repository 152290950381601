import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from './components/Login'; // Correct path and component name casing
import Quotation from './components/Quotation';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate(); // Hook for programmatic navigation

  useEffect(() => {
    // Check authentication status on app load from localStorage
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);

  const handleLogin = () => {
    // Handle login and store authentication status
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
    navigate('/quotation'); // Redirect to quotation after login
  };

  const handleLogout = () => {
    // Handle logout and clear authentication status
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    navigate('/login'); // Redirect to login after logging out
  };

  return (
    <Routes>
      {/* Public route: Login page */}
      <Route
        path="/login"
        element={<LoginPage handleLogin={handleLogin} />}
      />

      {/* Private route: Quotation page, only accessible if authenticated */}
      <Route
        path="/quotation"
        element={isAuthenticated ? <Quotation handleLogout={handleLogout} /> : <LoginPage handleLogin={handleLogin} />}
      />

      {/* Default route: Redirect to login or quotation based on authentication */}
      <Route
        path="/"
        element={isAuthenticated ? <Quotation handleLogout={handleLogout} /> : <LoginPage handleLogin={handleLogin} />}
      />
    </Routes>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
