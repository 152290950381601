import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/Quotation.css'; // Ensure you have your CSS file correctly linked
import Navbar from './Navbar';

const ViewQuotations = () => {
  const [quotations, setQuotations] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery); // State for debounced search query

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      const fetchedQuotations = response.data.allQuotations.map((quotation) => ({
        id: quotation.id,
        person: quotation.person,
        organisation: quotation.organisation,
        contactNumber: quotation.contact_number,
        addons: quotation.addons.map(addon => addon).join(', '),
        quotationNumber: quotation.quotationId, // Add quotation number
        center: quotation.centre // Add center selected
      }));
      setQuotations(fetchedQuotations);
    } catch (error) {
      console.error('Error fetching quotations:', error);
      setError('Failed to fetch quotations. Please try again.');
    }
  };

  // Function to download the PDF
  const downloadPDF = async (id) => {
    try {
      const response = await axios.get(`https://admin.machaxi.com/summer-api/download-pdf/${id}`, {
        responseType: 'blob',
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quotation_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setError('Failed to download PDF. Please try again.');
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Debounce search query
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Filtered quotations based on the debounced search query
  const filteredQuotations = quotations.filter((quotation) => {
    const lowercasedQuery = debouncedQuery.toLowerCase();
    return (
      quotation.person.toLowerCase().includes(lowercasedQuery) ||
      quotation.organisation.toLowerCase().includes(lowercasedQuery) ||
      quotation.center.toLowerCase().includes(lowercasedQuery) ||
      quotation.quotationNumber.toString().includes(lowercasedQuery) ||
      quotation.contactNumber.toString().includes(lowercasedQuery) || // Also searching by contact number
      quotation.addons.toLowerCase().includes(lowercasedQuery) // Search in add-ons
    );
  });

  return (
    <div className="dashboard-container">
      <Navbar />
      <div className="buyplan-container">
        <h2>Quotations</h2>
        {error && <p className="error-message">{error}</p>}

        {/* Search bar */}
        <input 
          type="text" 
          placeholder="Search" 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          className="search-bar" // Add CSS for styling
        />

        <table className="quotations-table">
          <thead>
            <tr>
              <th>Quotation Number</th> {/* New column for quotation number */}
              <th>Person</th>
              <th>Organisation</th>
              <th>Contact Number</th>
              <th>Center Selected</th> {/* New column for center selected */}
              <th>Add-ons</th>
              <th>Download PDF</th>
            </tr>
          </thead>
          <tbody>
            {filteredQuotations.slice().reverse().map((quotation) => (
              <tr key={quotation.id} className="quotation-row">
                <td>{quotation.quotationNumber}</td> {/* Render quotation number */}
                <td>{quotation.person}</td>
                <td>{quotation.organisation}</td>
                <td>{quotation.contactNumber}</td>
                <td>{quotation.center}</td> {/* Render center selected */}
                <td>{quotation.addons}</td>
                <td>
                  <button onClick={() => downloadPDF(quotation.id)}>Download PDF</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewQuotations;
